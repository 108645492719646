import React from 'react';

export default class Dynamic extends React.Component {
  state = { module: null };
  componentDidMount() {
    import(`./${this.props.path}`).then(module =>
      this.setState({ module: module.default })
    );
  }
  render() {
    const { module: Component } = this.state;
    return <>{Component && <Component />}</>;
  }
}
