import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Dynamic from '../components/dynamic';

import s from '../styles/pocetna.module.css';
import phoneIcon from '../images/phone-icon.svg';
import emailIcon from '../images/email-icon.svg';
import locationIcon from '../images/location-icon.svg';
import socialIcon from '../images/social-icon.svg';

export default () => (
  <Layout>
    <section className={s.heroSection}>
      <span className={s.background}></span>
      <h1>
        KESO<span style={{ marginLeft: "8px" }}>-PROMET</span>
      </h1>
      <div className="container">
        <div className="row justify-center">
          <div className={`${s.card} col-md-6 col-lg-4`}>
            <Link to="/prodaja-naftnih-derivata">
              <img
                src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1562422155/keso-promet/IMG_6628_2.jpg"
                alt="Prodaja naftnih derivata"
              />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1545184034/mahkeo-509030-unsplash_qfpqgs.jpg" alt="Prodaja naftnih derivata" /> */}
              <div className={s.card__nameTile}>Prodaja naftnih derivata</div>
            </Link>
          </div>
          <div className={`${s.card} col-md-6 col-lg-4`}>
            <Link to="/transport">
              <img
                src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1551116356/keso-promet/transport-kiperima.jpg"
                alt="Transport"
              />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1545184055/kendall-henderson-43316-unsplash_hllzw5.jpg" alt="Transport" /> */}
              <div className={s.card__nameTile}>Transport</div>
            </Link>
          </div>
          <div className={`${s.card} col-md-6 col-lg-4`}>
            <Link to="/zitopromet">
              <img
                src="https://res.cloudinary.com/ridjis/image/upload/f_auto/v1551388530/keso-promet/zitopromet/zp-najbolje-od-zita-cropped.jpg"
                alt="Žitopromet"
              />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/f_auto/v1551388330/keso-promet/zitopromet/zp-logo.jpg" alt="Žitopromet" />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1551116352/keso-promet/zitopromet.jpg" alt="Žitopromet" /> */}
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1545184054/ales-krivec-623956-unsplash_vimipr.jpg" alt="Žitopromet" /> */}
              <div className={s.card__nameTile}>Žitopromet</div>
            </Link>
          </div>
          <div className={`${s.card} col-md-6 col-lg-4`}>
            <Link to="/proizvodnja-i-prodaja-betona">
              <img
                src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1551392239/keso-promet/betonara.jpg"
                alt="Proizvodnja i prodaja betona"
              />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,w_345,f_auto/v1545186135/asdrubal-luna-485637-unsplash_etn7f2.jpg" alt="Proizvodnja i prodaja betona" /> */}
              <div className={s.card__nameTile}>
                Proizvodnja i prodaja betona
              </div>
            </Link>
          </div>
          <div className={`${s.card} col-md-6 col-lg-4`}>
            <Link to="/keso-invest">
              <img
                src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_650,f_auto/v1606944732/keso-promet/keso-invest/IMG-dc4722a64112fada4e801d7a3cf8288f-V.jpg"
                alt="Prodaja stambeno-poslovnih prostora"
              />
              {/* <img src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_250,f_auto/v1545186127/greg-rakozy-48621-unsplash_wcrh4g.jpg" alt="Prodaja stambeno-poslovnih prostora" /> */}
              <div className={s.card__nameTile}>Prodaja stambeno-poslovnih prostora</div>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className={s.aboutSection} id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h3 className={s.aboutSection__title}>O nama</h3>
          </div>
          <div className="col-lg-9">
            <p>
              Keso-Promet d.o.o. je firma sa tradicijom dugom skoro 30 godina.
              Od prvog dana pa sve do danas nudimo kvalitet i pouzdanost našim
              klijentima što je garancija našeg uspjeha i velikog broja
              poslovnih partnera na području Bosne i Hercegovine i inostranog
              tržišta.
            </p>
            <p>
              Bavimo se trgovinom naftnih derivata, izvođenjem građevinskih
              radova, proizvodnjom betona, pružamo usluge transporta i
              proizvodnjom i prodajom mlinarskih proizvoda (Žitopromet d.o.o.).
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className={s.contactSection} id="contact">
      <hr />
      <div className="container-fluid">
        <h2>Kontakt</h2>
        <div className="row no-gutters">
          <div className={`col-sm-6 col-lg-3 ${s.contactSection__card}`}>
            <div className={s.card__icon}>
              <img src={phoneIcon} alt="Phone icon" />
            </div>
            <div className={s.card__data}>
              <div className={s.data__type}>Broj telefona</div>
              <div className={s.data__content}>+387 56 490-125</div>
            </div>
          </div>
          <div className={`col-sm-6 col-lg-3 ${s.contactSection__card}`}>
            <div className={s.card__icon}>
              <img src={emailIcon} alt="Email icon" />
            </div>
            <div className={s.card__data}>
              <div className={s.data__type}>Email</div>
              <div className={s.data__content}>kesozitopromet@gmail.com</div>
            </div>
          </div>
          <div className={`col-sm-6 col-lg-3 ${s.contactSection__card}`}>
            <div className={s.card__icon}>
              <img src={locationIcon} alt="Location icon" />
            </div>
            <div className={s.card__data}>
              <div className={s.data__type}>Lokacija</div>
              <div className={s.data__content}>
                Karakaj 470А, Zvornik 75400, BiH
              </div>
            </div>
          </div>
          <div className={`col-sm-6 col-lg-3 ${s.contactSection__card}`}>
            <div className={s.card__icon}>
              <img src={socialIcon} alt="Social icon" />
            </div>
            <div className={s.card__data}>
              <div className={s.data__type}>Društvene mreže</div>
              <div className={s.data__content}>
                <p>
                  <span className="icon-facebook"></span>
                  <a
                    href="https://www.facebook.com/kesoprometdoo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kesoprometdoo
                  </a>
                </p>
                <p>
                  <span className="icon-instagram"></span>
                  <a
                    href="https://www.instagram.com/keso_promet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    keso_promet
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dynamic path="home-map" />
    </section>
  </Layout>
);
